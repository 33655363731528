// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/grudges/grudge-darker.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/grudges/grudge-dark.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/grudges/grudge-green.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/grudges/grudge-gold.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/images/grudges/grudge-white.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row.default .wrapper{justify-content:space-between}.wrapper .page-content__wrapper{width:100%}.wrapper .page-content__wrapper.grudge-darker{background:#122a15}.wrapper .page-content__wrapper.grudge-darker h1,.wrapper .page-content__wrapper.grudge-darker h2{color:#fff}.wrapper .page-content__wrapper.grudge-darker:after,.wrapper .page-content__wrapper.grudge-darker:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.wrapper .page-content__wrapper.grudge-dark{background:#3d6136}.wrapper .page-content__wrapper.grudge-dark h1,.wrapper .page-content__wrapper.grudge-dark h2{color:#fff}.wrapper .page-content__wrapper.grudge-dark:after,.wrapper .page-content__wrapper.grudge-dark:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.wrapper .page-content__wrapper.grudge-green{background:#cae0c7}.wrapper .page-content__wrapper.grudge-green:after,.wrapper .page-content__wrapper.grudge-green:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.wrapper .page-content__wrapper.grudge-gold{background:#eccc8a}.wrapper .page-content__wrapper.grudge-gold:after,.wrapper .page-content__wrapper.grudge-gold:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.wrapper .page-content__wrapper.grudge-white{background:#fff}.wrapper .page-content__wrapper.grudge-white:after,.wrapper .page-content__wrapper.grudge-white:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.wrapper .page-content__title{padding-left:30px;padding-right:30px}@media screen and (max-width:940px){.wrapper .page-content__title{padding-left:20px;padding-right:20px}}.wrapper .page-content.content-padding{padding:0 30px}.wrapper .page-content.content-padding:first-child{padding-left:0}.wrapper .page-content.content-padding:last-child{padding-right:0}@media screen and (max-width:940px){.wrapper .page-content{padding:20px!important;box-sizing:border-box}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
