<template lang="pug">
div.page-content__wrapper(:class="[grudeClass(block.bg_color)]" :style="styles")
  h1.page-content__title(v-if="block.h1" :style="headerTextColor(block.header_text_color)") {{ block.h1 }}
  h2.page-content__title(v-if="block.h2" :style="headerTextColor(block.header_text_color)") {{ block.h2 }}
  .page-content(
    v-html="content"
    :class="classes"
  )
</template>

<script>
import { breakpointMixin } from '@/mixins/breakpointMixin';
import { textColorMixin, grudgeMixin } from '@/mixins/colorMixin';

export default {
  name: 'PageContent',
  mixins: [breakpointMixin, textColorMixin, grudgeMixin],
  props: {
    block: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      deliveryTime: {
        deadline1: '',
        deadline2: '',
        deliveryDay1: '',
        deliveryDay2: '',
      },
    };
  },
  computed: {
    content() {
      return this.shortcodeParser(this.block.content);
    },
    styles() {
      return {
        width: this.isDesktop ? `${this.block.width - 5}%` : '100%',
      };
    },
    classes() {
      return {
        'content-padding': this.block.width < 100,
      };
    },

    redirectLink() {
      return this.$store.getters['shared/redirectLink'];
    },
    deliveryData() {
      return this.$store.getters['api/deliveryData'];
    },
  },
  async mounted() {
    if (
      this.block?.content?.includes('[deadline1]')
      || this.block?.content?.includes('[deadline2]')
      || this.block?.content?.includes('[leverdag1]')
      || this.block?.content?.includes('[leverdag2]')
    ) {
      await this.$store.dispatch('api/getDeliveryData', { zipcode: this.$auth.user?.recent_zipcode });

      const routes = this.deliveryData?.routes?.data;
      this.deliveryTime.deadline1 = `${routes[0]?.closing_day_localized} tot ${routes[0]?.closing_time}` || '';
      this.deliveryTime.deadline2 = `${routes[1]?.closing_day_localized} tot ${routes[1]?.closing_time}` || '';
      this.deliveryTime.deliveryDay1 = routes[0]?.delivery_day_localized || '';
      this.deliveryTime.deliveryDay2 = routes[1]?.delivery_day_localized || '';
    }
  },
  methods: {
    shortcodeParser(content) {
      let clone = content || '';
      const customer = this.$auth.user || {};
      const cart = customer.cart?.data || {};
      const address = cart.shippingaddress?.data || {};
      const fullAddress = `
        ${address.street} ${address.housenumber}${address.housenumber_suffix || ''},
        ${address.zipcode} ${address.city}
      `;
      const selectedRoute = address.routes?.data?.find((route) => route?.id === customer.current_order?.data?.route_id);
      const closingDate = selectedRoute ? this.$moment(selectedRoute.next_closing_date).format('dddd D MMMM YYYY') : '';

      clone = clone.replace('[leverdag_order]', this.$moment(cart.selected_delivery_date).format('dddd'));
      clone = clone.replace('[lever_datum]', this.$moment(cart.selected_delivery_date).format('D MMMM YYYY'));
      clone = clone.replace('[bezorgadres]', fullAddress);
      clone = clone.replace('[deadline_order]', closingDate);
      clone = clone.replace('[redirect]', this.redirectLink);
      clone = clone.replace('[deadline1]', this.deliveryTime.deadline1);
      clone = clone.replace('[deadline2]', this.deliveryTime.deadline2);
      clone = clone.replace('[leverdag1]', this.deliveryTime.deliveryDay1);
      clone = clone.replace('[leverdag2]', this.deliveryTime.deliveryDay2);

      return clone;
    },
  },
};
</script>

<style lang="scss">
.row.default .wrapper {
  justify-content: space-between;
}

.wrapper {
  .page-content__wrapper {
    @include grudge-backgrounds();

    width: 100%;
  }

  .page-content__title {
    padding-left: 30px;
    padding-right: 30px;

    @media screen and (max-width: $breakpoint-md) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .page-content.content-padding {

    padding: 0 30px;

    &:first-child{
      padding-left: 0;
    }

    &:last-child{
      padding-right: 0;
    }
  }
  @media screen and (max-width: $breakpoint-md) {

    .page-content {
      padding: 20px !important;
      box-sizing: border-box;
    }
  }
}
</style>
